<template>
  <div class="worship-visit-biography" style="height: 924px;position: relative;background: #ffffff">
    <div class="list-Add">
      <button>添加</button>
    </div>
    <div class="list-Bg" v-if="Lists.length > 0" :style="{backgroundImage: 'url(' + TEMPL_BIOGR_BG + ')'}">
      <h3 class="list-browse"><span class="sea-iconfont">&#xe627;</span>{{views}}</h3>
      <ul class="timeLine">
        <li
          v-for="(item,index) in Lists"
          :class="[index === 0 ? 'first-li' : '']"
          :style="{backgroundImage:'url(' + TEMPL_BIOGR_TL + ')', backgroundRepeat:'repeat-y' , backgroundPosition:'88.6% 0', backgroundSize: '5px'}"
          >
          <p
            :style="{backgroundImage:'url(' + TEMPL_BIOGR_TIME_POINT + ')', backgroundRepeat:'no-repeat' , backgroundPosition:'100% 0',backgroundSize: '30px'}">
<!--            :style="{backgroundImage:'url(' + TEMPL_BIOGR_TIME_BG + ')', backgroundRepeat:'no-repeat', backgroundPosition:'center center', backgroundSize: 'contain'}"-->
            <cite>
              {{item.happend_time}}
            </cite>
            <span v-html="mainBody(item.content)"></span>
<!--            <cite class="operation"-->
<!--                  :style="{backgroundImage:'url(' + TEMPL_BIOGR_TIME_BG + ')', backgroundRepeat:'no-repeat', backgroundPosition:'center center', backgroundSize: 'cover'}">-->
            <cite class="operation">
              <i class="sea-iconfont" @click="goCommentDetail(item.id)">&#xe66d;{{item.comment_num}}</i>
              <i class="sea-iconfont" @click="goEditBiography(item.id)">&#xe6b7;</i>
              <i class="sea-iconfont" @click="deleteBiography(item.id)">&#xe605;</i>
            </cite>
          </p>
          <div class="con flex-center"><img v-if="item.image" :src="item.image.url"><img v-else src="../../../assets/images/default-avatar.png"></div>
          <img class="border-logo" :style="{'right': activeRight}" src="../../../assets/images/hall/worship/biography-border-logo.png" />
        </li>
        <li>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'worship-visit-biography',
    data() {
      return {
        Lists: '',
        views: 0,
        TEMPL_BIOGR_BG: null, //传记背景
        TEMPL_BIOGR_TIME_BG: null, //传记时间底板背景
        TEMPL_BIOGR_TL: null, //传记时间线图
        TEMPL_BIOGR_TIME_POINT: null, //传记时间点
      }
    },
    computed: {
      activeRight() {
        let i = 0;
        console.log(i++)
        let min = 136
        return `${min}px`
      }
    },
    created() {
      this.hallId = '48e32dc432204326a86f8ce00285dab2';
      this.getBiographies();
      let template = {};
      if (template) {
        //this.TEMPL_BIOGR_BG = template.biogr_bg ? template.biogr_bg.url : require('../../../assets/images/hall/worship/biography-icon-bg.png');
        this.TEMPL_BIOGR_TIME_BG = template.biogr_time_bg ? template.biogr_time_bg.url : require('../../../assets/images/hall/worship/biography-time-icon-bg.png');
        this.TEMPL_BIOGR_TL = template.biogr_tl ? template.biogr_tl.url : require('../../../assets/images/hall/worship/back1.png');
        this.TEMPL_BIOGR_TIME_POINT = template.biogr_tl_point ? template.biogr_tl_point.url :  require('../../../assets/images/hall/worship/shaft-icon.png');
      }
    },
    methods: {
      goCommentDetail(id) {
        this.$router.push({
          path: '/biography/biographycmt',
          query: {
            id: id
          }
        })
      },

      goEditBiography(id) {
        this.$router.push({
          path: '/edit/biography',
          query: {
            id: id
          }
        })
      },

      deleteBiography(id) {
        let _this = this;
        _this.$api.hall.deleteBiography({id: id}).then(res => {
          _this.getBiographies();
        })
      },

      getBiographies() {
        let _this = this;
        let param = {
          hallId: this.hallId
        };
        _this.$api.hall.getBiographies(param)
          .then(res => {
            let datas = [];
            if (res.data) {
              res.data.data.forEach((data, i) => {
                datas.push(data);
              })
            }
            _this.Lists = datas;
          });

        _this.$api.hall.getBiographyVisitNum(param)
          .then(res => {
            _this.views = res.data.num;
          });

        _this.$api.hall.saveBiographyVisit(param).then(res => {
          console.log('添加传记访问记录成功');
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .list-Add {
    position: absolute;
    z-index: 99;
    top: 20px;
    right: 30px;
    button {
      width: 150px;
      height: 50px;
      background: linear-gradient(-90deg, #79AAD9 0%, #407DB2 100%);
      box-shadow: -2px 2px 5px 0px rgba(101, 101, 100, 0.5);
      border-radius: 25px;
      font-size: 18px;
      font-weight: bold;
      border: none;
      color: #FFFFFF;
    }
  }
  .timeLine {
    margin: 0px auto 0;
    overflow: hidden;
    position: relative;
    padding: 0 20px;
    li {
      position: relative;
      margin-right: 10%;
      padding-top: 30px;
      zoom: 1;
      display: flex;
      p {
        display: inline-block;
        width: 90%;
        font-size: 16px;
        text-align: left;
        padding-right: 4%;
        color: #1296db;
        float: right;
        span {
          display: block;
          font-size: 18px;
          font-weight: bold;
          color: #353535;
          background: #ffffff;
          padding: 15px 15px 40px 15px;
          width: 100%;
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
          box-shadow: 0px 0px 7px 3px rgba(101, 101, 100, 0.2);
          float: right;
        }
      }
      .border-logo {
        position: absolute;
        bottom: 20px;
        z-index: 222;
      }
      &:after {
       content: " ";
       display: block;
       height: 0;
       clear: both;
       visibility: hidden;
     }
    }
  }

  .timeLine li p .operation {
    width: 100%;
    text-align: left;
    color: #ffffff;
    background: linear-gradient(-90deg, #7AAADA, #407EB2);
    box-shadow: 1px 5px 5px 1px rgba(81, 81, 81, 0.3);
    border-bottom-left-radius: 100px;
    height: 34px;
    padding-left: 6%;
    line-height: 30px;

    i {
      margin-right: 20px;
    }
  }

  .timeLine li p cite {
    display: block;
    overflow: hidden;
    height: 30px;
    float: right;
    margin-bottom: 11px;
    border-radius: 8px;
    font-size: 24px;
    font-weight: 400;
    color: #646464;
    line-height: 36px;
    text-align: right;
    padding-right: 4px;
  }

  .timeLine li .con {
    width: 86px;
    height: 85px;
    background: url("~@/assets/images/hall/worship/logo-icon-bg.png") no-repeat center;
    background-size: cover;
  }

  .timeLine li .con img {
    width: 65px;
    height: 65px;
    display: block;
    margin: 10px;
    border-radius: 50%
  }

  .list-Bg {
    height: 927px;
    background: #ffffff;
    position: relative;
    z-index: 1;
    border-radius: 10px;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-x: hidden;
    overflow-y: auto;
    background-size: cover;

    .list-browse {
      position: absolute;
      top: 21px;
      left: 21px;
      z-index: 222;
      font-size: 24px;
      font-family: SimSun;
      font-weight: bold;
      color: #2070B4;
      @include w-flex;
      @include align-items;

      .sea-iconfont {
        color: #2070B4;
        font-size: 28px;
        margin-right: 10px;
      }
    }
    .first-li {
      padding-top: 100px;
    }
    &::-webkit-scrollbar {
       display: none; /* Chrome Safari */
    }
  }
</style>
